html {
  display: flex;
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #0a30af0f;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
